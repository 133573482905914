import React from 'react'
import { graphql } from 'gatsby'

import Layout from '~components/layout'
import SEO from '~components/seo'
import Search from '~components/search/search'

const SearchPage = props => {
  const { section, locale, type } = props.pageContext
  const { location } = props
  const search = props.data.search.nodes.find(({ node_locale }) => node_locale === locale)
  const settings = props.data.settings.nodes.find(({ node_locale }) => node_locale === locale)

  const { recommendedSearchTerms } = settings

  return (
    <Layout locale={locale} location={location} section={section} containsSearch>
      <SEO lang={locale} title={search?.metaTitle || search.title} description={search?.metaDescription} />
      <div>
        <hr className='accent-rule' aria-hidden />
        <div className='max-w-page py-8'>
          <h2 className='headline-1'>{search.title}</h2>
        </div>
        <hr className='accent-rule' aria-hidden />
      </div>
      <div className='max-w-page mx-auto'>
        <Search type={type} displayStyle='list' recommendedSearchTerms={recommendedSearchTerms} />
      </div>
    </Layout>
  )
}

export default SearchPage

export const pageQuery = graphql`
  {
    search: allContentfulSearchPage(filter: { slug: { eq: "search" } }) {
      nodes {
        node_locale
        title
        metaTitle
        metaDescription
      }
    }
    settings: allContentfulSettings(filter: { title: { eq: "Global Settings" } }) {
      nodes {
        node_locale
        recommendedSearchTerms: globalSearchRecommendedSearchTerms {
          ... on ContentfulPrimaryTopic {
            name
            slug
          }
          ... on ContentfulSecondaryTopic {
            name
            slug
          }
        }
      }
    }
  }
`
